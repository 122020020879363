export const SIGNIN_USER = "SIGNIN_USER";
export const SIGNUP_USER = "SIGNUP_USER";
export const SIGNOUT_USER = "SIGNOUT_USER";
export const AUTH_USER = "AUTH_USER";

export const GET_SUPPLIER_REQUEST = "GET_SUPPLIER_REQUEST";
export const GET_SUPPLIERS_REQUEST = "GET_SUPPLIERS_REQUEST";
export const GET_SUPPLIERS_SUCCESS = "GET_SUPPLIERS_SUCCESS";
export const GET_SUPPLIERS_FAILUAR = "GET_SUPPLIERS_FAILUAR";
export const CHANGE_TITLE = "CHANGE_TITLE";
export const CHANGE_SUPPLIER = "CHANGE_SUPPLIER";
export const CHANGE_CATEGORY = "CHANGE_CATEGORY";
export const CHANGE_SEARCH = "CHANGE_SEARCH";
export const CHANGE_CONDITION = "CHANGE_CONDITION";
export const CHANGE_ISCART = "CHANGE_ISCART";
export const SET_CART_INIT = "SET_CART_INIT";
export const SET_ROUTER_PARAM = "SET_ROUTER_PARAM";

export const GET_CATEGORYS_REQUEST = "GET_CATEGORYS_REQUEST";
export const GET_PRODUCTS_REQUEST = "GET_PRODUCTS_REQUEST";
export const GET_PRODUCTS_INIT = "GET_PRODUCTS_INIT";
export const ACTION_ISPRODUCTS = "ACTION_ISPRODUCTS";

export const CHANGE_CART = "CHANGE_CART";
export const GET_PRODUCTS_NEW = "GET_PRODUCTS_NEW";
export const GET_CART_INFORM = "GET_CART_INFORM";
export const SET_ORDERING_REQUEST = "SET_ORDERING_REQUEST";
export const GET_CHECKOUT_REQUEST = "GET_ORDERING_REQUEST";

export const GET_ORDER_HISTORY = "GET_ORDER_HISTORY";
export const GET_IS_HISTORY = "GET_IS_HISTORY";